import { Link } from 'gatsby';
import React from 'react';
import notes from '../images/notes-64.png';
import { createSnippet } from '../utils/helpers';

export const cardStyles = {
  backgroundColor: 'var(--bg-card)',
  color: 'var(--card-text)',
  borderRadius: '15px',
  padding: '15px',
  // paddingLeft: 0,
  boxShadow: '0 3px 16px rgba(0,0,0, .16)',
  // border: '1px solid var(--card-border)',
  display: 'flex',
  gap: '20px'
};
export default function ArticleCard({
  title,
  description,
  img,
  published,
  slug
}) {
  return (
    <Link itemProp="url" to={slug}>
      <div className="card card-1" style={cardStyles}>
        {/* <GatsbyImage image={img} alt="random-image" /> */}
        <div
          style={{ display: 'flex', width: '100%', flexDirection: 'column' }}
        >
          <div
            style={{
              display: 'flex',
              gap: '10px'
            }}
          >
            <img src={notes} alt="notes-icon" height={24} width={24} />
            <p className="date">{published}</p>
          </div>
          <h4>
            <span itemProp="headline">{title}</span>
          </h4>
          <p>{createSnippet(description)}</p>
          {/* <div className="tags">
          {tags?.map((tag, idx) => (
            <small key={idx} className="tag">
              {tag}
            </small>
          ))}
        </div> */}
        </div>
      </div>
    </Link>
  );
}
