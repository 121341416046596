import { Link, graphql } from 'gatsby';
import * as React from 'react';

import Seo from '../components/seo';
import me from '../images/me2-2.jpg';
import ArticleCard from '../components/article-card';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import locationIcon from '../images/location.png';

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout location={location} title="home" page="home">
      <section className="home">
        <div>
          <div className="intro">
            <div className="bio-avi">
              <StaticImage
                imgClassName="intro-img"
                src="../images/me2-2.jpg"
                alt="washo"
                loading="lazy"
                placeholder="blurred"
                // width={120}
                // height={120}
                quality={100}
                imgStyle={{
                  borderRadius: '100%'
                }}
              />
            </div>
            <div className="intro-txt">
              <h1>Hello, World!</h1>
              <p>
                I'm Wacu, a full-stack software developer, with a focus on the
                front-end, primarily the JavaScript and React ecosystem.
                <br />
                Interested in all things web development, performance
                optimization, and AI
                {/* <br />
                I love learning new technologies that enhance my work and writing
                about it. */}
                <br /> Welcome to my space!
                <div style={{ display: 'flex', alignItems: 'end' }}>
                  <img
                    src={locationIcon}
                    alt="location"
                    width={32}
                    height={32}
                  />{' '}
                  <span>Nairobi, Kenya</span>
                </div>
              </p>
              {/* <div className="intro-cta">
                <ButtonLink
                  to="https://www.linkedin.com/in/wacumbugua/"
                  variant="contained"
                  color="primary"
                >
                  linkedin
                </ButtonLink>
                <ButtonLink
                  to="https://github.com/w-mbugua"
                  variant="outlined"
                  color="primary"
                >
                 github
                </ButtonLink>
              </div> */}
            </div>
          </div>
          {/* <hr />
            <div style={{ width: '100%' }}>
              <h2>Get In Touch</h2>
              <div className="intro-socials socials">
                {socialLinks.map((link, i) => (
                  <SocialLink
                    key={link.site}
                    handle={link.handle}
                    label={link.site}
                    to={link.href}
                  />
                ))}
              </div>
            </div> */}
          <hr />
          <div className="latest-posts">
            <h2>Latest Blog Posts</h2>
            <div className="articles">
              {posts.slice(0, 6).map(post => {
                const title = post.frontmatter.title || post.fields.slug;
                const image = getImage(post.frontmatter.image);
                return (
                  <ArticleCard
                    key={post.frontmatter.title}
                    title={post.frontmatter.title}
                    description={post.frontmatter.description}
                    published={post.frontmatter.date}
                    img={image}
                    slug={post.fields.slug}
                    tags={post.frontmatter.tags}
                  />
                );
              })}
            </div>
            <br />
            <Link to="/blog">
              <h3>All Posts →</h3>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogIndex;

export const Head = () => <Seo title="Home" />;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          image_alt
          image_credit_text
          image {
            childImageSharp {
              gatsbyImageData(height: 200, width: 400)
            }
          }
        }
      }
    }
  }
`;
